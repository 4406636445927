<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <currency-input
      v-model="textData"
      class="w-full"
      :currency="filter.currency"
      :locale="locale"
      :disabled="disabled"
      :placeholder="filter.placeholder"
      :submit-on-enter-key="submitOnEnterKey"
      @update:modelValue="handleFilterInput"
      @clear="handleFilterInput"
    />
  </div>
</template>

<script>
import CurrencyInput from '@/components/inputs/currency-input'
import typeOf from 'just-typeof'
export default {
  components: {
    CurrencyInput
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    tags: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    submitOnEnterKey: {
      type: Boolean,
      required: false,
      default: false
    },
    locale: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      textData: Number(this.modelValue) || null
    }
  },
  computed: {
    disabled() {
      const disable = this.filter && this.filter.disable
      if (disable && typeOf(disable) === 'function') {
        const isDisabled = disable(this.tags)
        if (isDisabled) {
          if (this.textData) {
            this.setToNull()
          }
          return isDisabled
        }
      }
      return false
    }
  },
  methods: {
    handleFilterInput(v) {
      this.$emit(
        'update:modelValue',
        Number.isFinite(this.textData) || Number.isFinite(Number(this.textData))
          ? Number(this.textData)
          : null
      )
    },
    setToNull() {
      this.$emit('update:modelValue', null)
      this.textData = null
    }
  }
}
</script>
